<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        อนุมัติรับสินค้าเข้าคลัง : PU2-4
      </h3>
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th class="text-center">เลขที่เอกสาร</th>
              <th class="text-center">รหัสผู้ขาย</th>
              <th class="text-center">ชื่อผู้ขาย</th>
              <th class="text-center">วันที่รับสินค้า</th>
              <th class="text-center">มูลค่าสินค้ารับเข้า</th>
              <th class="text-center">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="9">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ item.receive_no ? item.receive_no : "" }}</td>
              <td>{{ item.vendor_code ? item.vendor_code : "" }}</td>
              <td>{{ item.vendor_name ? item.vendor_name : "" }}</td>
              <td>
                {{
                  item.receive_date
                    ? formatDate(item.receive_date.substring(0, 10))
                    : ""
                }}
              </td>
              <td class="text-end">{{ formatPrice(item.final_price) }}</td>

              <td class="text-center">
                <a
                  v-if="item.is_cancel === '0' && item.status === '3'"
                  style="color: #3ca76c; cursor: default; font-weight: 500"
                  >อนุมัติ</a
                >

                <a
                  v-if="item.is_cancel === '1'"
                  style="color: FireBrick; cursor: default; font-weight: 500"
                  >ยกเลิกแล้ว</a
                >

                <a
                  v-if="item.is_cancel === '2' && item.status === '3'"
                  style="color: grey; cursor: default; font-weight: 500"
                  >Complete</a
                >
              </td>

              <td>
                <div class="row">
                  <div @mousedown="hoverExportBtn()" class="col-4">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingExport
                            ? ''
                            : index == indexExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-printer-fill"> </i>
                      </div>
                    </button>
                  </div>

                  <div
                    class="col-sm-4"
                    v-if="item.status === '3' && item.is_cancel === '0'"
                  >
                    <button
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="role === 'STAFF' ? '' : handleApprove(item, '1')"
                    >
                      <i class="bi bi-save"></i>
                    </button>
                  </div>

                  <div
                    class="col-sm-4"
                    v-if="item.status === '3' && item.is_cancel === '0'"
                  >
                    <button
                      class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                      @click="role === 'STAFF' ? '' : handleApprove(item)"
                      style="color: red; cursor: pointer"
                    >
                      <i class="bi bi-x-circle"></i>
                    </button>
                  </div>
                </div>
              </td>

              <td
                v-if="
                  item.status === '3' &&
                  item.delete_able === true &&
                  role === 'SYSTEM ADMIN' &&
                  (item.is_cancel === '1' || item.is_cancel === '2')
                "
              >
                <div class="col-sm-6">
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                    @click="goToDelete(item, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      :typeDoc="typeDoc"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div v-if="isExport">
      <pdfFile
        :dataExport="dataExport"
        @generatedFile="generatedFile"
        :type="1"
      />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import purchaseApi from "@/api/purchase/";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "./components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import moment from "moment";

import pdfFile from "../../components/files/purchaseFile.vue";

import { Decode, Encode } from "@/services";

import numbro from "numbro";

export default {
  components: {
    DialogConfirmByPass,
    Pagination,
    Loader,
    pdfFile,
  },
  setup() {
    document.title = "BELUCA | อนุมัติรับสินค้าเข้าคลัง";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    arrCheckId: [],

    isEditItem: false,

    dialogConfirmByPass: false,
    typeDoc: "",

    isExport: false,
    loadingExport: false,
    indexExport: "",

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],

    dataExport: {
      id: 1,
      name: 2,
    },

    role: "",
  }),

  async created() {
    this.role = Decode.decode(localStorage.getItem("role")).replace(
      /^"(.*)"$/,
      "$1"
    );
    await this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      const objQuery = {
        status: "3",
        companyId: companyId,
        companyBranchId: companyBranchId,
      };

      try {
        responseData = await purchaseApi.purchaseOrder.getAllByStatus(objQuery);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
        this.checkEditItem(this.dataItemsShow);
      }
    },

    checkEditItem(item) {
      if (item) {
        item.forEach((element) => {
          element.purchaseOrderItems.forEach((element2) => {
            if (element2.item_amt !== element2.item_amt_recieve) {
              this.arrCheckId.push(element.id);
            }
          });
        });
        this.arrCheckId = this.arrCheckId.filter(function (
          value,
          index,
          array
        ) {
          return array.indexOf(value) === index;
        });
      }
    },

    // find product amt !== receive
    receiveItemNotComplete(items) {
      console.log(items);
      items.forEach((element, index) => {
        console.log({ element });
        element.purchaseOrderItems.forEach((element2) => {
          console.log({ element2 });
        });
      });
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push("/warehouse/WH4/new");
    },
    goToEdit(id) {
      this.$router.push({
        path: "/purchase/PU2-3/productIntoWarehouse",
        query: {
          id: id,
          method: "edit",
        },
      });
    },
    gotoImportExcel() {
      this.$router.push("/warehouse/WH4/importexcel");
    },
    goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.receive_no} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await purchaseApi.purchaseOrder.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },

    async statusApprove(isApprove, type) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        // status: isApprove ? 1 : 3,
        // receive_date: "",
        is_cancel: !isApprove ? "0" : type === "1" ? "2" : "1",
      };

      updateResponse = await purchaseApi.purchaseOrder.update(
        id,
        this.itemApprove
      );
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          type === "1"
            ? Swal.fire({
                icon: "success",
                title: `Complete !`,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {})
            : Swal.fire({
                icon: "error",
                title: `ยกเลิกรับสินค้าเข้าคลัง !`,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.updateProduct(isApprove);
              });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "ยกเลิกรับสินค้าไม่สำเร็จ !",
          Text: "เกิดข้อผิดพลาด !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },

    // update item
    async updateProduct(isApprove) {
      if (isApprove) {
        console.log(2);
        const updateResponse = await whApi.product.multiUpdatePo(
          this.itemApprove.purchaseOrderItems,
          "purchaseCW"
        );

        if (updateResponse.response_status === "ERROR") {
          Swal.fire({
            icon: "error",
            html: `ปรับสต๊อกสินค้าไม่สำเร็จ !`,
            timer: 4000,
          });
        }
        // else {
        //   this.$router.push({
        //     path: "/purchase/PU2-4",
        //     query: {},
        //   });
        // }
        // this.itemApprove = {
        //   ...this.itemApprove,
        //   // status: isApprove ? 1 : 2,
        // };
        // for (const element of this.itemApprove.purchaseOrderItems) {
        //   await this.getOneProduct(
        //     element.product.id,
        //     element.item_amt_recieve,
        //     element
        //   );
        // }
      }
    },

    async getOneProduct(id, inTransit, item) {
      // console.log({ inTransit });
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        // this.updateProductInTransit(inTransit, getOneResponse.data);
        this.updateProductInTransit(inTransit, getOneResponse.data, id);
        this.updateItemsReceive(item);
      }
    },
    async updateItemsReceive(item) {
      const data = {
        ...item,
        item_amt_new_recieve: 0,
        item_amt_old_recieve: 0,
        item_amt_recieve: item.item_amt,
      };

      const updateItemResponse = await purchaseApi.purchaseOrder.updateItem(
        item.id,
        data
      );

      if (updateItemResponse.response_status !== "SUCCESS") {
        Swal.fire({
          icon: "error",
          html: `สินค้ารหัส : ${item.product_code} จำนวน: ${inTransit} เพิ่มเข้า IN TRANSIT ไม่สำเร็จ !`,
          timer: 4000,
        });
      }
    },
    async updateProductInTransit(inTransit, item, id) {
      let updateResponse = [];

      const body = {
        // ...item,
        id: id,
        product_bring_qty:
          parseInt(item.product_bring_qty) - parseInt(inTransit),
        // product_in_transit:
        //   parseInt(item.product_in_transit) + parseInt(inTransit),
        product_in_transit: 0,
        isUpdateQty: true,
      };

      updateResponse = await whApi.product.update(item.id, body);
      if (updateResponse.response_status !== "SUCCESS") {
        Swal.fire({
          icon: "error",
          html: `สินค้ารหัส : ${item.product_code} จำนวน: ${inTransit} เพิ่มเข้า IN TRANSIT ไม่สำเร็จ !`,
          timer: 4000,
        });
      }
    },
    // update item

    // cancel and lock
    handleApprove(item, type) {
      if (type === "1") {
        this.typeDoc = type;
        this.itemApprove = { ...item };
        this.dialogConfirmByPass = true;
      } else {
        this.itemApprove = { ...item };
        this.dialogConfirmByPass = true;
      }
    },
    async submitConfirmPass(isApprove, type) {
      this.statusApprove(isApprove, type);
      this.dialogConfirmByPass = false;
      this.typeDoc = "";
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
      this.typeDoc = "";
    },
    // cancel and lock

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");

        return oldDate;
      } else {
        return "-";
      }
    },

    // async search() {
    //   this.loading = true;

    //   const status = ["3"];

    //   const responseSearch = await purchaseApi.purchaseOrder.search(
    //     {
    //       search: this.searchInput,
    //     },
    //     status
    //   );

    //   if (responseSearch.response_status === "SUCCESS") {
    //     this.dataItemsShow = responseSearch.data;
    //     this.tableItems = responseSearch.data;
    //     this.allData = responseSearch.data;
    //     this.loading = false;
    //   }
    // },

    // Export file
    goToExport(item, index) {
      this.dataExport = item;
      this.indexExport = index;
      this.loadingExport = true;
      this.isExport = true;
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },

    async search() {
      this.loading = true;
      let responseSearch = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      const objQuery = {
        status: "3",
        companyId: companyId,
        companyBranchId: companyBranchId,
      };

      this.searchInput
        ? (responseSearch = await purchaseApi.purchaseOrder.search(
            {
              search: this.searchInput,
            },
            objQuery
          ))
        : this.getAll();

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },
  },
};
</script>
